.faq-div1{
    display: flex;
    justify-content: center;
}
.faq-div2{
    width: 69vw;
    border: 1px solid rgb(230, 230, 230);
    padding: 30px;
    margin-top: 20px;
    border-radius: 15px;
    background-color: rgba(236, 233, 233, 0.37);
}
.faq-div2 h2{
    padding: 5px;
    margin: 10px 0px 10px 0px;
    font-size: 2vw;
    border-bottom:1px solid gray ;
 
}
.faq-div3{
    padding: 5px;
    border-bottom: 2px solid grey;
}
@media (min-width:300px) and (max-width:600px){
 .faq-div2{
    width: 90vw;
    padding: 10px;
 }
 .faq-div2 h2{
    font-size: 20px;
 }   
}
@media (min-width:600px) and (max-width:900px){
    .faq-div2{
       width: 90vw;
       padding: 10px;
    }
    .faq-div2 h2{
       font-size: 20px;
    }   
   }