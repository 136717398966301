/* .contactimage1{
    width: 100%;
    height: 20vw;
} */
.main-img-contact1{
    width: 100%;
    text-align: center;
}
.main-img-contact1 img{
    width: 90%;
    margin: 1vw 1vw 0px 1vw;
}
.contactimage2{
    display: none;
}
.contactimage1{
    display: block;
}
.contactus-banner{
    display: flex;
    justify-content: center;
}
.contact-us-banner-sub1{
   
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    border-radius: 20px;
    padding: 20px;
    margin: 20px;
    background-color:#DADADE;
}
.responsive-map{
    overflow: hidden;
    height: 0;
    padding-bottom: 30%;
    position: relative;
}
.responsive-map iframe{
    border: 0;
    height: 90%;
    width: 100%;
    left: 0;
    top: 0;
    position: absolute;
}
.main-img-contact1-phone{
    display: none;
}
@media (min-width:300px) and (max-width:600px){
    .main-img-contact1-phone{
        display: block;
    }

    .contactus-banner{
        flex-direction: column;
    }
    .contact-us-banner-sub1 h2{
   
        font-size: 20px;
    }
    .contact-us-banner-sub1 b{
        font-size: 14px;
    }

}