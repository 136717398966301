.design-tag{
    background-image: url(../../Assests/Screenshot\ 2024-02-15\ 162423.png);
    text-align: center;
    font-size: 2vw;
    padding: 2vw;

}
.design-body{
    background-color: rgb(218, 218, 222);
   
}
.design-upper{
    display: flex;
    justify-content: center;
    gap: 2vw;
    padding: 3vw;
}
.design-upper img{
    width: 40vw;
}
.design-upper-content h2{
    font-weight: 100; 
    width: 25vw;
}
.design-upper-content {
    font-size: 3.2vw;
    font-family: 'Times New Roman', Times, serif;
}
.design-bottom{
    display: flex;
    justify-content: center;
    gap: 2vw;
    flex-direction: row-reverse;
    margin-top: 3vw;
}
.design-bottom img{
    width: 40vw;
}
@media (min-width:300px) and (max-width:600px){
     
    .design-tag h2{
       
        font-size: 4vw;
        padding: 2vw;
    
    }
    .design-upper{
        display: flex;
        gap: 2vw;
        padding: 3vw;
    }
    .design-upper img{
        width: 50vw;
    }
    .design-upper-content h2{
        font-weight: 100; 
        width: 24vw;
        /* border: 2px solid; */
     
    
    
    }
    .design-upper-content {
        font-size: 3.2vw;
        font-family: 'Times New Roman', Times, serif;
    }
    .design-bottom{
        display: flex;
        justify-content: center;
        gap: 2vw;
        flex-direction: row-reverse;
        margin-top: 3vw;
        
    }
    .design-bottom img{
        width: 50vw;
    }



}