.testimonialVideo{
    display: flex;
    gap: 100px;
    padding: 20px 5vw 20px 5vw;
    background-color: #DADADE;
}
.testimonial-images{
    display: flex;
    /* border:2px solid; */
    justify-content: space-evenly;
    padding: 20px 5vw 20px 5vw;
    background-color: #DADADE;
}
.testimonial-images img{
    width:20vw
}
.testimonial-1-1 h1{
   text-align:center ;
   background-image: url(../../Assests/Screenshot\ 2024-02-15\ 162423.png);
   font-size: 2vw;
   padding: 2vw;
}
.testimonial-1-1 span{
    color:#99e921;
}
.testimonial-1 p{
    background-color: #DADADE;
    padding: 2vw 5vw 20px 5vw;
}
.testimonialVideo video{
    width: 25vw;
 }
 .testimonial-21{
    border: 2px solid;
 }
 .testimonial-2{
    border: 10px solid white;
    width: 500px;
    text-align: center;
    padding: 2vw;
 }
 .testimonial-2 p{
    margin: 10px;
 }
.testimonial-2 iframe{
    width: 80%;
    height: 60%;
}
@media (min-width:300px) and (max-width:600px){
    .testimonialVideo{
        text-align: center;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        width: 100%;
        padding: 20px 5vw 20px 5vw;
        background-color: #DADADE;
    }
    .testimonial-1-1 h1{
        text-align:center ;
        font-size: 5vw;
        padding: 2vw;
     }
     .testimonial-1 p{
         background-color: #DADADE;
         padding: 5vw 5vw 20px 5vw;
     }
     .testimonialVideo video{
        width: 350px;
        height: 300px;
     }
     .testimonial-images{
        flex-direction: column;
        width: 100%;
     }
     .testimonial-2{
        border: 10px solid white;
        width: 100%;
        text-align: center;
        padding: 2vw;
     }
     .testimonial-images img{
        width:70vw
    }
}
@media (min-width:600px) and (max-width:900px){
    .testimonialVideo{
        text-align: center;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        width: 100%;
        padding: 20px 5vw 20px 5vw;
        background-color: #DADADE;

    }
    .testimonial-1 h1{
        text-align:center ;
        font-size: 2vw;
        padding: 2vw;
     }
     .testimonial-1 p{
         background-color: #DADADE;
         padding: 5vw 5vw 20px 5vw;
     }
     .testimonialVideo video{
        width: 500px;
        height: 300px;
     }
     .testimonial-images{
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
     }
     .testimonial-images img{
        width:70%
    }

}