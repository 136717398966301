.dprojects {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; 
    margin: 10px;
    background-image: url(../../Assests/Screenshot\ 2024-02-15\ 162423.png);
}

.dprojects img {
    width: 300px;
    height: 200px;
    display: inline-block;
    

}
.video-container{
    width: 98vw;
}
.video-container img {
    width: 100%;
}
.video-container1{
    display: none;
}

.computer-images{
    display: flex;
    justify-content: center;
}
.image1{
    position: relative;

}
.content{
    top: 0;
    left: 0;
    position: absolute;
    background: rgba(0, 0, 0, 0.6);
    opacity: 0;
    border-radius: 20px;
    transition: 0.6s;
    color: aliceblue;
    width: 100%;
    height: 100%;
    
}
.content span{
    color: #ADF802;
}
.content h2, .content p {
    padding: 20px;
}
.content:hover{
    opacity: 1;
}
.content> * {
    transform: translateY(25px);
    transition: transform 0.6s;
}
.content:hover > * {
    transform: translate(0px);

}
.complete-details-tag{
    text-align: center;
    padding: 2vw;
    margin: 1vw;
    background-color: #DADADE;;
}
.sub-images-div{
    background-image: url(../../Assests/Screenshot\ 2024-02-15\ 162423.png);
}
@media (min-width:300px) and (max-width:600px){
.complete-details-tag h1{
    font-size: 13px;

}
.video-container{
    display: none;

}
.video-container img{
    display: none;
}
.video-container1{
    display: block;
    width: 98vw;
    
}
.video-container1 img {
    width: 100%;
   
}

    
}
