  .main-img-about{
    width: 100%;
    text-align: center;
  }
  .about-us-color-span span{
    color: #99E921;

  }
  .main-img-about img{
    width: 90%;
    margin: 1vw 1vw 0px 1vw;
  }
  .about-us-tag{
    text-align: center;
    padding:2vw ;
    background-image: url(../../Assests/Screenshot\ 2024-02-15\ 162423.png);

  }
  .about-us-tag h1{
    font-size: 2vw;
  }
  .about-us-tag-content{
    text-align: center;
    background-color:#DADADE;
    display: flex;
    justify-content: center;
  }
  .about-us-tag-content p{
    padding: 2vw;
    width: 70vw;
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .ceo-tag{
    text-align: center;
    padding:2vw ;
    background-image: url(../../Assests/Screenshot\ 2024-02-15\ 162423.png);
  }
  .ceo-tag h2{
    font-size: 2vw;
  }
  .words{
    display: flex;
    justify-content: center;
    gap: 3vw;
    background-color:#DADADE;
    padding: 2vw;
  }
  .words-left{
    width: 40vw;
    padding: 3vw;
  }
  .words-left p{
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .words-left h1{
    margin-top: 1vw;
    margin-bottom: 1vw;
  }
  .words-right{
    width: 40vw;
  }
  .mission-div{
    display: flex;
    padding: 3vw;
    justify-content: center;
    gap: 5vw;
    background: linear-gradient(0deg, rgba(239,198,184,1) 0%,  rgba(226,221,219,1) 100%);
  }
  .mission-img-div{
    width: 40vw;
  }
  .mission-img-div img{
    width: 100%;
  } 
  .words-right img{
    width: 100%;
  }
  .mission-tag-div{
    text-align: center;
    padding:2vw ;
    background-image: url(../../Assests/Screenshot\ 2024-02-15\ 162423.png);
  }
  .mission-tag-div h2{
    font-size: 2vw;
  }
  .mission-content-div p{
    font-size: 1rem;
    line-height: 1.5rem;
    width: 40vw;
    margin-top: 1vw;
  }
  .mission-content-div{
    padding: 3vw;
  }
  .values-tag{
    text-align: center;
    padding: 2vw;
    background-image: url(../../Assests/Screenshot\ 2024-02-15\ 162423.png);
  }
  .values-tag h2{
    font-size: 2vw;
  }
  .values-left{
    display: flex;
    justify-content: center;
    margin-top: 2vw;
    gap: 2vw;

  }
  .values-left1{
    width: 30vw;
    padding: 2vw;
    border-radius: 15px;
    background-color:#FFF7F1;
  }

  .values-left-img{
    width: 15vw;
    text-align: center;
  }
  .values-left-img img{
    width: 100%;
    border-radius: 15px
  }
  .main-img-about-phone{
    display: none;
  }
  @media (min-width:300px) and (max-width:600px) {

    .main-img-about-phone{
      width: 100%;
      display: block;
      text-align: center;
    }
    .main-img-about-phone img{
      width: 90%;
      margin: 1vw 1vw 0px 1vw;
    }
    .main-img-about{
      display: none;
    }
    .about-us-tag{
      padding: 3vw;    
    }
    .about-us-tag h1{
      font-size: 5vw;
    }
    .about-us-tag-content{
      text-align:left;
      background-color:#DADADE;
      display: flex;
      justify-content: center;
    }
    .about-us-tag-content p{
     width: 90vw;
      font-size: 1rem;
      line-height: 1.5rem;
    }
    .ceo-tag{
      padding: 3vw;
    }
    .ceo-tag h2{
      font-size: 6vw;
    }
    .words{
    flex-direction: column-reverse;
    }
    .words-right{

    }
    .words-left{
      width: 90vw;
      padding: 3vw;
    }
    .words-left p{
      font-size: 1rem;
      line-height: 1.5rem;
    }
    .words-left h2{
     font-size: 18px;
    }
    .words-right{
      width: 90vw;
    }
    .mission-div{
    flex-direction:column;
    }
    .mission-img-div{
      width: 90vw;
    }
    .mission-img-div img{
      width: 100%;
    } 

    .mission-tag-div{
      text-align: center;
      padding: 3vw;
      background-image: url(../../Assests/Screenshot\ 2024-02-15\ 162423.png);
    }
    .mission-tag-div h2{
      font-size: 6vw;
    }
    .mission-content-div h2{
      font-size: 18px;
    }
    .mission-content-div p{
      width: 90vw;
  
    }
    .mission-content-div{
      padding: 1vw;
    }
    .values-tag {
      padding: 3vw;
    }
   .values-tag h2{
    font-size: 6vw;
   }
    .values-left{
    flex-direction: column;
    }
    .values-left1{
      width: 90vw;
      padding: 2vw;
      border-radius: 15px;
      background-color:#FFF7F1;
    }
    .values-left-img{
      width: 80vw;
      text-align: center;
    }
    .values-left-img img{
      width: 100%;
      border-radius: 10px;
      margin-left: 2vw;
    }
    
  }
  @media(min-width:600px) and (max-width:900px){

   
    .about-us-tag{
     font-size: 3vw;
      padding: 1vw;
    
  
    }
    .about-us-tag-content{
      text-align:left;
      background-color:#DADADE;
      display: flex;
      justify-content: center;
    }
    .about-us-tag-content p{
  width: 90vw;
      font-size: 1rem;
      line-height: 1.5rem;
    }
    .words{
    flex-direction: column-reverse;
    }
    .words-right{

    }
    .words-left{
      width: 90vw;
      padding: 3vw;
    }
    .words-left p{
      font-size: 1rem;
      line-height: 1.5rem;
    }
    .words-left h1{
      margin-top: 1vw;
      margin-bottom: 1vw;
    }
    .words-right{
      width: 90vw;
    }
    .mission-div{
    flex-direction:column;
    }
    .mission-img-div{
      width: 90vw;
    }
    .mission-img-div img{
      width: 100%;
    } 

    .mission-tag-div{
      text-align: center;
      padding: 1vw;
      background-image: url(../../Assests/Screenshot\ 2024-02-15\ 162423.png);
    }
   
    .mission-content-div p{
      width: 90vw;
  
    }
    .mission-content-div{
      padding: 1vw;
    }
   
    .values-left{
    flex-direction: column;
    }
    .values-left1{
      width: 90vw;
      padding: 2vw;
      border-radius: 15px;
      background-color:#FFF7F1;
    }
    .values-left-img{
      width: 80vw;
      text-align: center;
    }
    .values-left-img img{
      width: 100%;
      border-radius: 10px;
      margin-left: 2vw;
    }
    
  }




