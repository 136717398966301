.error-div{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
}
.error-sub-div{
text-align: center;
}
.error-sub-div h1{
    color: rgb(43, 43, 43);
}