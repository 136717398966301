.main-complete{
    width: 96vw;
    text-align: center;
}
.main-complete img{
    width: 100%;
}

.MainCompletedProjects{
    background-image: url(../../Assests/Screenshot\ 2024-02-15\ 162423.png);
}
.video-container1{
    display: none;
}
@media (min-width:300px) and (max-width:600px){
   
    .main-complete{
       display: none
    }
    .main-complete img{
        display: none
    }
    .video-container1{
        display: block;
        width: 96vw;
        
    }
    .video-container1 img {
        width: 100%;
       
    }
}