.project-main-image{
    display: flex;
    justify-content: center;
    gap: 40px;
    padding:  5px;
    font-size: 16px;
    border-radius:5px;
    margin-top: 20px;
}
.project-main-image div{
    width: 40vw;
}
.DprojectStrip span{
   color: #99e921;
}
.project-main-image img{
    border-radius: 15px;
}
.image-gallery{
    width: 100%;
    height: 100vh;
    background: black;
    position: fixed;
    display: flex;
    top: 100px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 1000000;
}
.gallery-button{
    position: absolute;
    top: 30px;
    right: 100px;
    padding: 5px;
    background-color: black;
    color: white;
    border: 2px solid black;
    font-size: 30px;
    cursor: pointer;
}
@media (min-width:300px) and (max-width:600px){
    .image-gallery{
        display: none;
    }
 .project-main-image{
    flex-direction: column;
    gap: 5px;
 }
 .project-main-image div{
    width: 90vw;
}
.DprojectStrip h2{
    text-align: center;
}
.gallery-button{
    top: 30px;
    right: 30px;
    padding: 5px;
    background-color: black;
    color: white;
    border: 2px solid black;
    font-size: 30px;
    cursor: pointer;
}
    
}
@media (min-width:600px) and (max-width:900px){
    .project-main-image{
       flex-direction: column;
       gap: 5px;
    }
    .project-main-image div{
       width: 90vw;
   }
   .DprojectStrip h2{
       text-align: center;
   }
   .gallery-button{
    top: 30px;
    right: 30px;
    padding: 5px;
    background-color: black;
    color: white;
    border: 2px solid black;
    font-size: 30px;
    cursor: pointer;
}
       
   }
     
    