.carrerimage1{
    text-align: center;
    
}
.carrerimage1 img{
    width: 80%;
    height: 30vw;
}
.career-tag {
    text-align: center;
    padding: 2vw;
    background-color: rgb(225, 223, 220);
   
}
.career-tag h1{
    margin-bottom: 20px;
}
.career-tag h2{
    font-weight: 300;
   
}
.perksOfWorking-main{
    margin-top: 50px;
    display: flex;
    justify-content: center;
  
   
}
.perksOfWorking{
    width: 60vw;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    gap: 3vw;
    padding-bottom: 4vw;
}
.perksOfWorking-sub1{
  
     width: 150px;
}
.perksOfWorking-sub1 h2{
    font-size: 40px ;
    margin-top: 25px;
    line-height: 45px;
}
.perksOfWorking-sub1 span{
    color: greenyellow;
    font-size: 40px
}
.perksOfWorking-main ul li{
    border-bottom:.5px solid rgba(198, 193, 193, 0.811);
    margin-top: 25px;
    padding: 3px;
}
.ourOffice{
    margin: 2vw;
    text-align: center;
    font-size: 2vw;
    

}
.ourOffice span{
   
    color: greenyellow;
}
.officepics1{
    display: flex;
    justify-content: center;
   
}

.pic1{
    width: 280px;
    height: 250px;
}

.pic2 {
    width: 600px;
    height: 250px;
}
.pic1 img, .pic2 img{
    width: 100%;
    height: 100%;
}
.main-vacancy-div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
}
.experience-button{
    display: flex;
    justify-content: space-between;
    margin: 0px;
    padding: 0px;
}
.experience-button h4{
    margin: 0px;
    padding: 0px;
    
}
.experience-buttonSub{
    margin: 0px;
    padding: 0px;
    height:10px
}
.vacancy-div{
    
    width: 70vw;
    border-radius: 25px;
    background-color: rgba(255, 255, 255, 0.9);
}
.vacancy-div h1{
    font-size: 20px;
    margin-top: 2vw;
    border-bottom: 1px solid rgba(210, 199, 199, 0.646);
}
.vacancy-div h4{
margin: 1vw;
}
.vacancy-div button{
    background-color: yellowgreen;
    color: white;
    padding: 1vw;
    border: none;
    margin: 1vw;
    border-radius: 10px;
    font-weight: 700;
}
.vacancy-div button:hover{
    cursor: pointer;
    
}
.contact-us-banner-main{
    display: flex;
    justify-content: center;
    padding: 20px;

}
.contact-us-banner-sub{
    width: 70vw;
    display: flex;
   
   align-items: center;
    flex-direction: column;
    /* border: 1px solid yellowgreen; */
    border-radius: 20px;
    padding: 20px;
    margin: 20px;
    background-color:#DADADE;
   
}
.sucess-at{
    margin: 3vw;
    width: 90vw;
   
    

}
.sucess-at h2{
    padding: 10px;
    border-bottom: 1px solid rgba(210, 199, 199, 0.646);
  
    font-size: 1.5vw ;
}
.sucess-at span{
    color: yellowgreen;
    
}
.sucess-at ul li {
    margin: 10px;
}
.sucess-at ul {
    margin-top:20px ;
}

@media (min-width:300px) and (max-width:600px){


    .career-tag h1{
       font-size: 6vw;
    }
    .vacancy-div h1{
        font-size: 5vw;
        margin-top: 5vw;
    }
    .main-vacancy-div{
        display: flex;
        justify-content: space-around;
        flex-direction: column;
    }
    .vacancy-div{
        /* border:2px solid */
        margin: 3vw;
        width: 93vw;
        flex-direction: column;
    }
    .vacancy-div h4{
    margin: 1vw;
    font-weight: 300;
    }
    .vacancy-div button{
     
      
        padding: 2vw;
        border: none;
        margin: 1vw;
        border-radius: 5px;
        font-weight: 700;
    }
    .vacancy-div button:hover{
        cursor: pointer;
        
    }
    .career-tag {
        text-align: center;
        padding: 2vw;
        background-color: rgb(225, 223, 220);
       
    }
    .career-tag h1{
        margin-bottom: 10px;
    }
    .career-tag h2{
        font-weight: 300;
        font-size: 16px;
       
    }
    .perksOfWorking-main{
        margin-top: 50px;
        display: flex;
        justify-content: center;
      
       
    }
    .perksOfWorking{
        width: 90vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 10px;
        padding: 40px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        gap: 3vw;
        padding-bottom: 4vw;
    }
    .perksOfWorking-sub1{
      
         width: 250px;
    }
    .perksOfWorking-sub1 h2{
        font-size: 30px ;
        margin-top: 25px;
        line-height: 45px;
    }
    .perksOfWorking-sub1 span{
        color: greenyellow;
        font-size: 30px ;
        
      
    }
    .perksOfWorking-main ul li{
        border-bottom:.5px solid rgba(198, 193, 193, 0.811);
        margin-top: 25px;
        padding: 3px;
    }
    .ourOffice{
        margin: 6vw;
        text-align: center;
        font-size: 6vw;
        
    
    }
    .ourOffice span{
       
        color: greenyellow;
    }
    .officepics1{
        display: flex;
        flex-direction: column;
        justify-content: center;
      
       
    }
    
    .pic1{
        width: 95vw;
        height: 230px;
        text-align: center;
    }
    
    .pic2 {
        width: 95vw;
        height: 170px;
        text-align: center;
    }
    .pic1 img, .pic2 img{
        width: 90%;
        height: 100%;
    }
    .main-vacancy-div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      
    }
    .experience-button{
        display: flex;
        justify-content: space-between;
        margin: 0px;
        padding: 0px;
        flex-direction: column;
    }
    .experience-button h4{
        margin: 10px;
        padding: 0px;
        
    }
    .experience-buttonSub{
        margin: 0px;
        padding: 0px;
        height:10px
    }
    
    .contact-us-banner-main{
        display: flex;
        justify-content: center;
        padding: 20px;
    
    }
    .contact-us-banner-sub{
        width: 90vw;  
    }
    .contact-us-banner-sub h2, .contact-us-banner-sub b{
        font-size: 15px;

    }
    .sucess-at{
        margin: 3vw;
        width: 90vw;
       
        
    
    }
    .sucess-at h2{
        padding: 10px;
        border-bottom: 1px solid rgba(210, 199, 199, 0.646);
      
        font-size: 6vw ;
    }
    .sucess-at span{
        color: yellowgreen;
        
    }
    .sucess-at ul li {
        margin: 10px;
    }
    .sucess-at ul {
        margin-top:20px ;
    }


}
@media (min-width:600px) and (max-width:900px){

    .career-tag h1{
        font-size: 4vw;
    }
    .vacancy-div h1{
        font-size: 3vw;
        margin-top: 5vw;
    }
    .main-vacancy-div{
        display: flex;
        justify-content: space-around;
        flex-direction: column;
    }
    .vacancy-div{
        /* border:2px solid */
        margin: 3vw;
        width: 93vw;
        flex-direction: column;
    }
    .vacancy-div h4{
    margin: 1vw;
    font-weight: 300;
    }
    .vacancy-div button{
     
        
        padding: 2vw;
        border: none;
        margin: 1vw;
        border-radius: 5px;
        font-weight: 700;
    }
    .vacancy-div button:hover{
        cursor: pointer;
        
    }
    .career-tag {
        text-align: center;
        padding: 2vw;
        background-color: rgb(225, 223, 220);
        
    }
    .career-tag h1{
        margin-bottom: 10px;
    }
    .career-tag h2{
        font-weight: 300;
        font-size: 16px;
        
    }
    .perksOfWorking-main{
        margin-top: 50px;
        display: flex;
        justify-content: center;
        
        
    }
    .perksOfWorking{
        width: 90vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 10px;
        padding: 40px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        gap: 3vw;
        padding-bottom: 4vw;
    }
    .perksOfWorking-sub1{
      
        width: 450px;
    }
    .perksOfWorking-sub1 h2{
        font-size: 30px ;
        margin-top: 25px;
        line-height: 45px;
    }
    .perksOfWorking-sub1 span{
        color: greenyellow;
        font-size: 30px ;
        
        
    }
    .perksOfWorking-main ul li{
        border-bottom:.5px solid rgba(198, 193, 193, 0.811);
        margin-top: 25px;
        padding: 3px;
    }
    .ourOffice{
        margin: 6vw;
        text-align: center;
        font-size: 3vw;
        
        
    }
    .ourOffice span{
        
        color: greenyellow;
    }
    .officepics1{
        display: flex;
        flex-direction: column;
        justify-content: center;
    
        
    }
    
    .pic1{
        width: 95vw;
        height: 330px;
        text-align: center;
    }
    
    .pic2 {
        width: 95vw;
        height: 270px;
        text-align: center;
    }
    .pic1 img, .pic2 img{
        width: 90%;
        height: 100%;
    }
    .main-vacancy-div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
    }
    .experience-button{
        display: flex;
        justify-content: space-between;
        margin: 0px;
        padding: 0px;
        flex-direction: column;
    }
    .experience-button h4{
        margin: 10px;
        padding: 0px;
        
    }
    .experience-buttonSub{
        margin: 0px;
        padding: 0px;
        height:10px
    }
    
    .contact-us-banner-main{
        display: flex;
        justify-content: center;
        padding: 20px;
        
    }
    .contact-us-banner-sub{
        width: 90vw;  
    }
    .contact-us-banner-sub h2, .contact-us-banner-sub b{
        font-size: 15px;

    }
    .sucess-at{
        margin: 3vw;
        width: 90vw;
        
        
        
    }
    .sucess-at h2{
        padding: 10px;
        border-bottom: 1px solid rgba(210, 199, 199, 0.646);
        
        font-size: 5vw ;
    }
    .sucess-at span{
        color: yellowgreen;
        
    }
    .sucess-at ul li {
        margin: 10px;
    }
    .sucess-at ul {
        margin-top:20px ;
    }
}