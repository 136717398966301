.dropdown-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    padding: 10px;
    z-index: 1;
  }
  
  .dropdown-container:hover .dropdown-content {
    display: block;
  }
  .projects-option-div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40vw;
  }
  .projects-option-div a{
    text-decoration: none;
    color: black;
  }
  .projects-option-div p{
    margin-top: 20px;
    font-weight: 600;
  }
  .projects-option-div a:hover{
    color: blue;
  }