.work-card{
    /* width: 98vw; */
    text-align: center;
    
}
.work-card img{
    width: 70%;  
}


@media (min-width:300px) and (max-width:600px){
    .work-card{
        /* width: 95vw; */
        text-align: center;
        
    }
    .work-card img{
        width: 90%;  
    }
  
    
    
}
@media(min-width:600px) and (max-width:900px){

    .work-card{
       
        text-align: center;
        
    }
    .work-card img{
        width: 100%;  
    }
    
}