.main-img {
  width:70%;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  height: 70vh;
  background-image: url(../../Assests/flash3.jpeg);
  background-size: 100% 100%;
  animation: change 15s ease-in-out 1s infinite normal none;
  /* animation: name duration timing-function delay iteration-count direction fill-mode; */
}
@keyframes change {
  0% {
    background-image: url(../../Assests/flash1.jpeg);
  }
  20% {
    background-image: url(../../Assests/flas2.jpeg);
  }
  40% {
    background-image: url(../../Assests/flash3.jpeg);
  }
  60% {
    background-image: url(../../Assests/flash5.jpeg);
  }
  80%{
    background-image: url(../../Assests/flash7.jpeg);
  }
  
  100% {
    background-image: url(../../Assests/flash6.jpeg);
  }
}

.main-tag {
  margin-top: 2vw;
  margin-bottom:2vw;
  text-align: center;
  padding: 1vw;
  font-size: 1vw;
  background-image: url(../../Assests/Screenshot\ 2024-02-15\ 162423.png);
}
.main-tag span{
  color: #99e921;
}

.service-tag{
  background-color:#DEE1E6;
  text-align: center;
  padding: 1vw;
}

.service h2{
    text-align: center;
    font-size: 2vw;
}
.service-card{
    background-color: white; 
    padding: 2vw;
    border-radius: 15px;
    -webkit-box-shadow: 13px 13px 13px -7px rgba(0,0,0,0.54);
    -moz-box-shadow: 13px 13px 13px -7px rgba(0,0,0,0.54);
     box-shadow: 13px 13px 13px -7px rgba(0,0,0,0.54);

}
.service-card-img{
    width:15vw
}
.service-card-img img{
    width: 100%
}
.service-card-text{
    text-align: center;
}
.service-main{
    display: flex;
    padding: 2vw;
    background-image: url(../../Assests/Screenshot\ 2024-02-15\ 162423.png);
    justify-content: space-evenly;
}
.service-card-text h3{
    font-size: 1.5vw;
}
.service-txt{
    margin-top: 3vw;
    background-color:#DEE1E6;
    padding: 2vw;
}
.service-txt h2{
    text-align: center;
    font-size: 2vw;
}
.service-txt p{
    text-align: center;
    font-size: 1.3vw;
    margin-top: 1vw;
}
.our-div{
  text-align: center;
  padding: 1vw;
  background-image: url(../../Assests/Screenshot\ 2024-02-15\ 162423.png);
}
.our-div span{
    color:#000000;
   
}
.finest h2{
    font-size: 2vw;
    transform: translate(-140px);
    color:#99e921;
 
}
.arts h2{
    font-size: 2vw;
    text-align: center;
    /* margin-left: 10vw; */
    color:#99e921;
}
.our-images{
display: flex;
justify-content: center;
}
.how{
    background-image: url(../../Assests/Screenshot\ 2024-02-15\ 162423.png);
    text-align: center;
    padding: 2vw;  
}
.how h2{
  font-size: 2vw;
}
.how span{
  color:#99e921;
}
@media (min-width:300px) and (max-width:600px){
  .main-img {
    width: 80%;
    height: 20vh;
    
  }
  .main-tag h1{
    font-size: 4vw;
  }
  .main-tag {
    margin-top: 2vw;
    margin-bottom:2vw;
    padding: 4vw;
   
  
  }  
  .architecture{
    flex-direction: column-reverse;  
  }
  .architecture-text{
    width: 90vw;
  }   
  .architecture-text h1{
    font-size: 5vw;
  }
  .architecture-text ul li{
    margin: 1vw;
    font-size: 3vw;
  } 
  .architecture-img {
    width: 95vw;
    text-align:start
  }
  .architecture-img img {
    width: 90%;
    margin: 3vw;
    border-radius: 10px;
  }
  .service-tag h2{
    margin-top: 2vw;
    margin-bottom:2vw;
    font-size: 5vw;
  }
  .service-main{
    margin-top: 2vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2vw;
}
.service-card{
  border: 3px solid grey;
  padding: 2vw;
  text-align: center;

}
.service-card-img{
  width:50vw;
  text-align: center;
  display: flex;
  justify-content: center;
}
.service-card-img img{
  width: 90%;
  text-align: center;
}
.service-card-text{
  text-align: center;
}

.service-card-text h3{
  font-size: 3vw;
  /* border: 2px solid; */
}

.service-txt h2{
  text-align: center;
  font-size: 5vw;
}
.service-txt p{
  text-align: center;
  font-size: 16px;
  margin-top: 1vw;
  text-align: left;
  padding: 5vw 5vw 20px 5vw;

   
}
.finest h2{
   text-align: center;
   font-size: 5vw;
   /* margin-left: 15vw; */
   transform: translate(-80px);

}
.arts h2{
  text-align: center;
  font-size: 5vw;
}
.how{
  margin-top: 2vw;
  margin-bottom:2vw;
  font-size: 2vw;
}
.how h2{
  font-size: 5vw;
}

}
@media(min-width:600px) and (max-width:900px){
   

  .main-img {
    width: 80%;
    height: 28vh;
    
  }
  
  .main-tag {
    margin-top: 2vw;
    margin-bottom:2vw;
    font-size: 2vw;
  }  
  .architecture{
    flex-direction: column-reverse;  
  }
  .architecture-text{
    width: 90vw;
  }   
  .architecture-text h1{
    font-size: 5vw;
  }
  .architecture-text ul li{
    margin: 1vw;
    font-size: 3vw;
  } 
  .architecture-img {
    width: 95vw;
    text-align:start
  }
  .architecture-img img {
    width: 90%;
    margin: 3vw;
    border-radius: 10px;
  }
  .service-tag h2{
    margin-top: 2vw;
    margin-bottom:2vw;
    font-size: 4vw;
  }
  .service-main{
    margin-top: 2vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2vw;
}
.service-card{
  border: 3px solid grey;
  padding: 2vw;
  text-align: center;

}
.service-card-img{
  width:50vw;
  text-align: center;
  display: flex;
  justify-content: center;
}
.service-card-img img{
  width: 90%;
  text-align: center;
}
.service-card-text{
  text-align: center;
}

.service-card-text h3{
  font-size: 3vw;
  /* border: 2px solid; */
}
/* .service-txt{
  margin-top: 3vw;
  background-color:#DEE1E6;
  padding: 2vw;
} */
.service-txt h2{
  text-align: center;
  font-size: 3vw;
}
.service-txt p{
  text-align: center;
  font-size: 2.3vw;
  margin-top: 1vw;

   
}
.finest h2{
   text-align: center;
   font-size: 4vw;
   /* margin-left: 15vw; */
   transform: translate(-80px);

}
.arts h2{
  text-align: center;
  font-size: 4vw;
}
.how{
  margin-top: 2vw;
  margin-bottom:2vw;
  font-size: 2vw;
}




}
@media(min-width:900px) and (max-width:1200px){
   .main-img{
    width:80%;
    height: 30vh;
   }
.architecture {
  display: flex;
  gap: 3vw;
  padding: 3vw;
  background: #abbaab;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #ffffff, #abbaab);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #abbaab, #ffffff); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}
.architecture-img {
  width: 50vw;
 
  text-align: center;
}
.architecture-img img {
  width: 80%;
  border-radius: 20px;
}
.architecture-text {
  width: 40vw;
  display: flex;
  margin-top: 2vw;
  flex-direction: column;
  align-items: center;
  
}
.architecture-text ul li{
    margin: 1vw;
    font-size: 1.4vw;
}
.service-tag{
  background-color:#DEE1E6;
    /* background-image: url(../../Assests/Screenshot\ 2024-02-15\ 162423.png); */
    /* margin-top: 2vw;
    margin-bottom:2vw; */
    text-align: center;
    padding: 1vw;
}

.service h2{
    text-align: center;
    font-size: 3vw;
}
.service-card{
    /* border: 1px solid grey; */
    background-color: white;
    
    padding: 2vw;
    border-radius: 15px;
    -webkit-box-shadow: 13px 13px 13px -7px rgba(0,0,0,0.54);
    -moz-box-shadow: 13px 13px 13px -7px rgba(0,0,0,0.54);
     box-shadow: 13px 13px 13px -7px rgba(0,0,0,0.54);

}
.service-card-img{
    width:15vw
}
.service-card-img img{
    width: 100%
}
.service-card-text{
    text-align: center;
}
.service-main{
    display: flex;
    padding: 2vw;
    background-image: url(../../Assests/Screenshot\ 2024-02-15\ 162423.png);
    justify-content: space-evenly;
}
.service-card-text h3{
    font-size: 1.5vw;
}
.service-txt{
    margin-top: 3vw;
    background-color:#DEE1E6;
    padding: 2vw;
}
.service-txt h2{
    text-align: center;
    font-size: 2vw;
}
.service-txt p{
    text-align: center;
    font-size: 1.3vw;
    margin-top: 1vw;
}
.our-div{
  margin-top: 2vw;
  margin-bottom:2vw;
  text-align: center;
  padding: 1vw;
  background-image: url(../../Assests/Screenshot\ 2024-02-15\ 162423.png);
}
.our-div span{
    color: goldenrod;
   
}
.finest h2{
  font-size: 2vw;
    transform: translate(-140px);
 
}
.arts h2{
    font-size: 2vw;
    text-align: center;
    /* margin-left: 10vw; */
}
.our-images{
display: flex;
justify-content: center;
}
.how{
    background-image: url(../../Assests/Screenshot\ 2024-02-15\ 162423.png);
    font-size: 2vw;
    text-align: center;
    padding: 2vw;    
}
  



}
