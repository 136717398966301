*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.spinner{
    /* position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); */
  margin-top: 50vh;
  margin-left: 48vw;
}