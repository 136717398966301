.footer-logo-div{
    text-align: center;
    
}
.footer-logo-div img{
    width: 7vw;
}
.main-footer{
    background-color: #DADADE;
    padding: 1vw 4vw;
}
.footer-top-right a{
    text-decoration: none;
    color: black;


}
.footer-top-right div{
    
  
   

}

.connect{
    font-size: 2vw;
}
.footer-top{
    display: flex;
    padding-top: 3vw;
    gap: 23vw;
   
}
.footer-top-right{
    display: flex;
    gap: 9vw;
   
  

}
.footer-top-right ul{
    list-style: none;
}
.footer-top-right ul li{
    margin-top: .5vw;
}
.footer-down{
    margin-top: 3vw;
    display: flex;
    justify-content: space-between;
}
.icons{
    display: flex;
    gap: 1vw;
}
.icons a{
    text-decoration: none;
    color: black;
}

.adress{
width: 30vw;
display: flex;
gap: .5vw;
margin-top: .5vw;
}
.footer-tag{
    margin-top: 2vw;
    text-align: center;
    font-size: 2vw;
    padding: 1vw;
    background-image: url(../../Assests/Screenshot\ 2024-02-15\ 162423.png); 
}
.keywordsfooter{
    padding: 20px;
    border-top: 2px solid;
   
    background-color: #DADADE;
}
@media (min-width:300px) and (max-width:600px){
    .footer-logo-div{
        text-align: center;
       
        
    }
    .footer-logo-div img{
        width: 30vw;
      
    }
    .main-footer{
        background-color: #DADADE;
        padding: 1vw 4vw;
    }
    
    .connect{
        font-size: 2vw;
    }
    .footer-top{
       flex-direction:column;
        padding-top: 3vw;
        
    }
    .footer-top-right{
        display: flex;
        gap: 10vw;
        justify-content: center;
        text-align: center;
        flex-wrap: wrap;
       
    }
    .footer-top-right div{
        
        width: 150px;
    }
    .footer-top-right ul{
        list-style: none;
    }
    .footer-top-right ul li{
        margin-top: .5vw;
    }
    .footer-down{
        margin-top: 3vw;
        display: flex;
     
        flex-direction: column;
        justify-content: space-between;
    }
    .footer-down-left{
        margin-top: 5vw;
        margin-bottom: 4vw;
       
        text-align: center;
    }
    .icons{
        display: flex;
        gap: 1vw;
        justify-content: center;
       
    }
    .icons a{
        text-decoration: none;
        color: black;
    }
    .footer-down h4{
        font-size: 8vw;
    }
    
    .adress{
    width: 90vw;
    display: flex;
    gap: 2vw;
    margin-top: 5vw;
   
    }
    .footer-tag{
        margin-top: 2vw;
        text-align: center;
        font-size: 4vw;
        padding: 1vw;
        background-image: url(../../Assests/Screenshot\ 2024-02-15\ 162423.png); 
    }
    
}


@media(min-width:600px) and (max-width:900px){

        .footer-logo-div{
            text-align: center;
           
            
        }
        .footer-logo-div img{
            width: 30vw;
          
        }
        .main-footer{
            background-color: #DADADE;
            padding: 1vw 4vw;
        }
        
        .connect{
            font-size: 2vw;
        }
        .footer-top{
           flex-direction:column;
            padding-top: 3vw;
            
        }
        .footer-top-right{
            display: flex;
            gap: 10vw;
            flex-wrap: wrap;
          
        
        }
        .footer-top-right ul{
            list-style: none;
        }
        .footer-top-right ul li{
            margin-top: .5vw;
        }
        .footer-down{
            margin-top: 3vw;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        .footer-down-left{
            margin-top: 4vw;
            margin-bottom: 4vw;
        }
        .icons{
            display: flex;
            gap: 1vw;
        }
        .icons a{
            text-decoration: none;
            color: black;
        }
        .footer-down h4{
            font-size: 8vw;
        }
        
        .adress{
        width: 90vw;
        display: flex;
        gap: 2vw;
        margin-top: 5vw;
       
        }
        .footer-tag{
            margin-top: 2vw;
            text-align: center;
            font-size: 4vw;
            padding: 1vw;
            background-image: url(../../Assests/Screenshot\ 2024-02-15\ 162423.png); 
        }
        
    }