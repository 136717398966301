.Inspiration-Intro-tag h1{
    padding: 2vw;
    text-align: center;
    background-image: url(../../Assests/Screenshot\ 2024-02-15\ 162423.png);
}
.Inspiration-Intro-tag span{
    color: #99E921;
}
.inspiration-first-image{
    width: 98vw;
    text-align: center;
}
.inspiration-first-image img{
    width: 90%;
}
.Inspiration-Intro{
    background-color: rgb(238, 238, 238);
    padding: 2vw;
    display: flex;
    justify-content: center;
    
}
.Inspiration-Intro ul{
    list-style: none;
    width: 60vw;
   
}
.Inspiration-first-div1{
    width: 50vw;
    margin: 2vw;
    text-align: center;

}
.Inspiration-first-div1 img{
    width: 100%;
}
.Inspiration-first-div {
    display: flex;
   }
.Inspiration-first-div p{
    width: 40vw;
    margin: 2vw;

}
.insprationVideo{
    width: 30vw;
}
.insprationVideo video{
    width: 100%;
    height: 40vw;
}
.inspiration-cloud{
    display: flex;
    justify-content: center;
    gap: 2vw;
   
}
.inspiration-cloud p {
    width: 40vw;
    margin-bottom: 2vw;
    padding: 5px;
}
.insprationVideo3{
   
}
.insprationVideo3 iframe{
    width:40vw
}

@media (min-width:300px) and (max-width:600px) {
    .Inspiration-Intro-tag h1{
        padding: 2vw;
        font-size:20px;
        
    }
    .Inspiration-Intro{
        background-color: rgb(238, 238, 238);
        padding: 1vw;
        display: flex;
        justify-content: center;
       
        
    }
    .inspiration-first-image{
        width: 98vw;
      
        text-align: center;
    }
    .inspiration-first-image img{
        width: 90vw;
        height: 200px;
      
    }
    .Inspiration-Intro ul{
        list-style: none;
        width: 90vw;
       
    }
    .Inspiration-first-div1{
        width: 100%;
       margin: 1px;
        text-align: center;
    
    }
    .Inspiration-first-div1 img{
        width: 100%;
    }
    .Inspiration-first-div {
        display: flex;
        flex-direction:column
       }
    .Inspiration-first-div p{
        width: 90vw;
        margin: 2vw;
    
    }
    .insprationVideo{
        width: 90vw;
    }
    .insprationVideo video{
        width: 100%;
        height: 50vw;
    }
    .inspiration-cloud{
        flex-direction: column;
        gap: 2vw;
    }
    .inspiration-cloud p {
        width: 90vw;
        margin-bottom: 2vw;
    }
    .insprationVideo3 iframe{
        width:90vw;
        height: 200px
    }
    
}
@media (min-width:600px) and (max-width:900px) {
    .Inspiration-Intro-tag h1{
        padding: 2vw;
        font-size:20px;
        
    }
    .Inspiration-Intro{
        background-color: rgb(238, 238, 238);
        padding: 1vw;
        display: flex;
        justify-content: center;
       
        
    }
    .inspiration-first-image{
        width: 98vw;
        text-align: center;
    }
    .inspiration-first-image img{
        width: 90vw;
      
    }
    .Inspiration-Intro ul{
        list-style: none;
        width: 90vw;
       
    }
    .Inspiration-first-div1{
        width: 100%;
        margin: 1px;
        text-align: center;
    
    }
    .Inspiration-first-div1 img{
        width: 100%;
    }
    .Inspiration-first-div {
        display: flex;
        flex-direction:column
       }
    .Inspiration-first-div p{
        width: 90vw;
        margin: 2vw;
    
    }
    .insprationVideo{
        width: 90vw;
    }
    .insprationVideo video{
        width: 100%;
        height: 50vw;
    }
    .inspiration-cloud{
       flex-direction: column;
        gap: 2vw;
    }
    .inspiration-cloud p {
        width: 90vw;
        margin-bottom: 2vw;
    }
    
}