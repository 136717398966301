.contact-div{
    display: flex;
    background-color:#DADADE;
    padding-bottom: 3vw;
}
.contactimage1{
    text-align: center;

}
.contactimage1 img{
    width: 100%;
    height: 300px;
}
.contact-tag{
    padding: 2vw;
   
    text-align: center;
   
    background-image: url(../../Assests/Screenshot\ 2024-02-15\ 162423.png);
}
.contact-tag h2{
    font-size: 2vw;
}
.contact-tag span{
    color:#99e921;
}
.contact-left{
    width: 50vw;
 
    display: flex;
    justify-content: center;
   
}
.contact-sub-left{
   
    width: 40vw;

}
.contact-left1{
    padding: 2vw;
}
.contact-left1 h2{
    font-size: 3vw;
    padding-top: 1vw;
    padding-bottom: 1vw
}
.adress-main{
    padding: 1vw;
}
.adress1{
    margin-top: 2vw;
    display: flex; 
    gap: 3px;
    padding-left: 1vw;
    font-size: 1.3vw;
}
.aa{
    /* border: 2px solid */
    margin-top: 4px;
}

.contact-right{
    width: 40vw;
    padding-top: 2vw;

}

.contact-name{
    display: flex;
    gap: 2vw;
    justify-content: center;
    margin-top: 2vw;
}
.contact-name input{
    width: 15vw;
    height: 3vw;
    border-radius: .5vw;
    border:  none;
    padding: 1vw;
}
.contact-message{
   display: flex;
   justify-content: center;
   margin-top: 2vw;
   border:  none;
   

}
.contact-message textarea{
    width: 32vw;
    border-radius: .5vw;
    border:  none;
    padding: 1vw;
    height: 15vw;
}


.submit-button{
    display: flex;
    justify-content: center;
    margin-top: 2vw;
}
.submit-button button{
    width: 20vw;
    height: 3vw;
    border-radius: .5vw;
    border:  none;
    background-color: black;
    color: rgb(255, 255, 255);
    font-size: 1.2vw;
    cursor: pointer;

}
button:disabled{
    background-color:rgb(65, 66, 67);
    cursor: not-allowed;
}

@media (min-width:300px) and (max-width:600px){

    .contact-div{
        flex-direction: column;
        padding-bottom: 3vw;
    }
    .contact-tag{
        margin-top: 2vw;
        margin-bottom:2vw;
       
    }
    .contact-tag h2{
        font-size: 5vw;
    }
    .contact-left{
        width: 90vw;
        padding: 2vw;
      
      
       
    }
    .contact-sub-left{
        width: 80vw;
    
    }
    .contact-left1{
        padding: 2vw;
    }
    .contact-left1 h3{
        font-size: 5vw;
    }
    .adress-main{
        padding: 1vw;
    }
    .adress1{
        margin-top: 2vw;
        gap: 3px;
        padding-left: 1vw;
        font-size: 1.3vw;
    }
    .adress1 p {
        font-size:4vw ;  
    }
    .aa{
    }
    .contact-right{
        width: 90vw;
        padding-top: 5vw;
    
    }
    .form-quote h2{
        /* border:2px solid ; */
      margin-left: 15px;
      font-size: 5vw;

    }
    .contact-name{
        display: flex;
        gap: 2vw;
        justify-content: center;
        margin-top: 2vw;
    }
    .contact-name input{
        width: 40vw;
        height: 6vw;
        border-radius: .5vw;
        border:  none;
        padding: 1vw;
    }
    .contact-message{
       display: flex;
       justify-content: center;
       margin-top: 2vw;
       border:  none;
       
    
    }
    .contact-message textarea{
        width: 82vw;
        border-radius: .5vw;
        border:  none;
        padding: 1vw;
        height: 40vw;
    }
    
    
    .submit-button{
   
        display: flex;
        justify-content: center;
        margin-top: 2vw;
    }
    .submit-button button{
        width: 30vw;
        height: 7vw;
        border-radius: .5vw;
        border:  none;
        background-color: black;
        color: rgb(255, 255, 255);
        font-size: 3vw;
    
    }

}
@media(min-width:600px) and (max-width:900px){

    .contact-div{
        flex-direction: column;
        padding-bottom: 3vw;
    }
    .contact-tag{
        margin-top: 2vw;
        margin-bottom:2vw;
        font-size: 4vw;
    }
    .contact-left{
        width: 90vw;
        padding: 2vw;
      
       
    }
    .contact-sub-left{
        width: 80vw;
    
    }
    .contact-left1{
        padding: 2vw;
    }
    .contact-left1 h3{
        font-size: 3vw;
    }
    .adress-main{
        padding: 1vw;
    }
    .adress1{
        margin-top: 2vw;
        gap: 3px;
        padding-left: 1vw;
        font-size: 1.3vw;
       
    }
    .adress1 p {
        font-size:4vw ;  
    }
    .adress1 div{
        
    }
    .aa{
        margin-top:12px ;
    }
    
    .contact-right{
        width: 90vw;
        /* border: 2px solid; */
        padding-top: 5vw;
    
    }
    .contact-name{
        display: flex;
        gap: 2vw;
        justify-content: center;
        margin-top: 2vw;
    }
    .contact-name input{
        width: 40vw;
        height: 6vw;
        border-radius: .5vw;
        border:  none;
        padding: 1vw;
    }
    .contact-message{
       display: flex;
       justify-content: center;
       margin-top: 2vw;
       border:  none;
       
    
    }
    .contact-message textarea{
        width: 82vw;
        border-radius: .5vw;
        border:  none;
        padding: 1vw;
        height: 40vw;
    }
    
    
    .submit-button{
    
        /* border: 2px solid; */
        display: flex;
        justify-content: center;
        margin-top: 2vw;
    }
    .submit-button button{
        width: 30vw;
        height: 7vw;
        border-radius: .5vw;
        border:  none;
        background-color: black;
        color: rgb(255, 255, 255);
        font-size: 3vw;
    
    }
     

}
