.connectionpopus{
          position: fixed;
          z-index: 100;
          display: flex;
          color: black;
          flex-direction: column;
          width: 4.5vw;
          gap: 2vw;
          align-items:center;
          object-fit:cover;
          border-radius: 20px 0 0 20px;
          background-color: rgba(255,255,255, 0.7);
          margin-top:2vw;
          padding: 2vw;
          top: 100px;
          right: 0px;
}
@media(min-width:300px) and (max-width:600px){
    .connectionpopus{
        width: 50px;
    }
}
@media(min-width:600px) and (max-width:900px){
    .connectionpopus{
        width: 70px;
    }
}