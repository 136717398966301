.main-nav-div{
    background-image: url("../../Assests/Screenshot\ 2024-02-15\ 162423.png");
    transition: all 1s ease-in-out;
}
  
.sticky {
    position: fixed;
    top: 0;
    width: 99vw;
    background-image: url("../../Assests/Screenshot\ 2024-02-15\ 162423.png");
    z-index: 100;
    
  }

  .dropdown-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
    
  }
  
  .dropdown-content p{
    border-bottom: 1px solid rgb(189, 189, 189);
    padding: 1vw;
  }  
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    width: 20vw;
    border-radius: 15px;
    background-color: rgb(220, 210, 210);
  }
  
  .dropdown-container:hover .dropdown-content {
    display: block;
  }
.nav-div{
    display: flex;
    justify-content: space-between;
    background-image: url("../../Assests/Screenshot\ 2024-02-15\ 162423.png");
   
}
.Logo-div{
  cursor: pointer;  
}
.Logo-div:hover{
    transform: scale(1.04) translate(0, 0);
    transition: transform 2s ease-in;
    -webkit-box-shadow: 14px 18px 45px -15px rgba(0,0,0,0.75);
    -moz-box-shadow: 14px 18px 45px -15px rgba(0,0,0,0.75);
     box-shadow: 14px 18px 45px -15px rgba(0,0,0,0.75); 
}
.Logo-div img{
    width: 70px;
    margin-left: 1vw;
    margin-top: 1vw;
   
}

.link-div ul {
    list-style: none;
    display: flex;
    margin-top: 1.5vw;
    padding: 1vw;
    gap: .7vw;
    margin-left: 20vw;
  
  
}
.link-div ul li a{
    text-decoration: none;
    color: rgb(0, 0, 0);
    border-radius: 10px;
    padding: 1vw;
}
.link-div ul li {
    text-decoration: none;
    color: rgb(0, 0, 0);
    font-weight: 600;
 
    padding: 1vw;
    font-size: 20px;
    border-radius: 10px;
}

.link-div ul li a:hover{
    color: #FFFFFF;  
    background-color: #000000;
}


@media(min-width:300px) and (max-width:600px){
    .main-nav-div{
        background-image: url("../../Assests/Screenshot\ 2024-02-15\ 162423.png");
        transition: all 1s ease-in-out;
    }
    .Logo-div {
        width: 70px;
        /* margin: 7vw; */
    }
   
   .Logo-div img{
       width: 50px;       
   }
 
   .link-div ul {
       list-style: none;
       display: none;
      
     
   }
   .hambug{
       margin-top: 1vw;
       /* margin-right:5vw; */
       display: block;
   }

}
@media(min-width:600px) and (max-width:900px){


    .main-nav-div{
        background-image: url("../../Assests/Screenshot\ 2024-02-15\ 162423.png");
        transition: all 1s ease-in-out;
    }
    .Logo-div {
        width: 70px;
      
    }
   
   .Logo-div img{
       width: 50px;       
   }
 
   .link-div ul {
       list-style: none;
     
       margin-left: 5px;
    }
    .link-div ul li {
        text-decoration: none;
        color: rgb(0, 0, 0);
        font-weight: 600;
        padding: 1vw;
        font-size: 13px;
        border-radius: 10px;
    }
    .dropdown-content {
    
        width: 30vw;
       
      }



}
 @media(min-width:900px) and (max-width:1200px){
    .Logo-div {
        width: 70px;
      
    }
   
   .Logo-div img{
       width: 120px;       
   }
  
 
    .link-div ul {
      
       margin-left: 5vw;
    }
    .link-div ul li {
        text-decoration: none;
        color: rgb(0, 0, 0);
        font-weight: 600;
        padding: 1vw;
        font-size: 20px;
        border-radius: 10px;
    }
    .dropdown-content {
    
        width: 30vw;
       
      }
      



} 