.Main-Privacy-div{
    margin: 5vw 9vw 5vw 9vw;
}
.Main-Privacy-div p{
    margin-top: 2vw;
}
.Main-Privacy-div ul li{
    margin-top: 1vw;
}
.Main-Privacy-div h2{
    margin-top: 2vw;
    margin-bottom: 1vw;
}